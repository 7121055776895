<template>
  <div>
    <p v-if="!isMobile()" class="headline black--text font-weight-bold">Campaigns</p>
    <div v-if="isLoading">
      <!-- DESKTOP -->
      <div v-if="!isMobile()">
        <div class="d-flex justify-center">
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="350"
            height="200"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="350"
            height="200"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="350"
            height="200"
            type="card"
          ></v-skeleton-loader>
        </div>
      </div>
      <!-- MOBILE -->
      <div v-else>
        <div class="d-flex justify-center">
          <v-skeleton-loader
              class="mx-auto pa-1 round-16"
              width="1200"
              height="200"
              type="card"
            ></v-skeleton-loader>
        </div>
        <br>
        <div class="d-flex justify-center">
          <v-skeleton-loader
              class="mx-auto pa-1 round-16"
              width="1200"
              type="card"
              height="200"
            ></v-skeleton-loader>
        </div>
        <br>
        <div class="d-flex justify-center">
          <v-skeleton-loader
              class="mx-auto pa-1 round-16"
              width="1200"
              type="card"
              height="200"
            ></v-skeleton-loader>
        </div>
      </div>
    </div>
    <div v-else>
      <v-row class="mt-0" v-if="itemData.length > 0">
        <div class="col-md-4 col-sm-6" v-for="list in itemData" :key="list.cartId">
            <!-- DESKTOP -->
          <v-card v-if="!isMobile()" class="overflow-hidden mb-5 round-16" outlined elevation="1">
            <v-card-text >
              <!-- <v-divider class="mb-3 mt-2"></v-divider> -->
              <div class="row pt-0">
                <div class="col-md-12">
                  <p class="caption mb-0">{{list.date}}</p>
                </div>
                <!-- <div class="col-md-6">
                  <v-chip label class="caption">Selesai</v-chip>
                </div> -->
                <div class="col-md-12 pt-0">
                  <p class="mb-0 black--text font-weight-bold text-16">{{ list.name }}</p>
                  <p class="d-flex align-center mb-0 body-2">{{ list.qty }} Accounts</p>
                  <!-- <p class="mb-0">{{list.order.date_added | dateFormat2}}</p> -->
                </div>
              </div>
              <!-- <v-divider class="mb-3 mt-1"></v-divider> -->
              <div class="row">
                <div class="col-md-6 col-6">
                  <v-btn elevation="1" outlined rounded @click="goToDetail(list.id)" class="text-capitalize full-width" color="primary">View Detail</v-btn>
                </div>
                <div class="col-md-6 col-6">
                  <v-btn rounded @click="downloadFile(list.name, list.id)" class="text-capitalize full-width" color="primary"><i class="fas fa-download mr-2"></i> Export Excel</v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
            <!-- MOBILE -->
          <v-card v-else class="overflow-hidden round-16" outlined elevation="3">
            <v-card-text >
              <div class="row pt-0">
                <div class="col-md-12">
                  <!-- <p class="caption mb-0">{{list.date_added | dateFormat2}}</p> -->
                  <p class="caption mb-0">{{ list.date }}</p>
                </div>
                <!-- <div class="col-md-6">
                  <v-chip label class="caption">Selesai</v-chip>
                </div> -->
                <div class="col-md-12 pt-0">
                  <p class="mb-0 black--text font-weight-bold text-16">{{ list.name }}</p>
                  <p class="d-flex align-center mb-0 body-2">{{ list.qty }} Accounts</p>
                  <!-- <p class="mb-0">{{list.order.date_added | dateFormat2}}</p> -->
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-6">
                  <v-btn rounded elevation="1" outlined @click="goToDetail(list.id)" class="text-capitalize full-width" color="primary">View Detail</v-btn>
                </div>
                <div class="col-md-6 col-6">
                  <v-btn rounded @click="downloadFile(list.name, list.id)" class="text-capitalize full-width" color="primary"><i class="fas fa-download mr-2"></i> Export Excel</v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
      <div v-else class="d-flex justify-center">
        <div class="col-md-4 pl-1 pr-1">
          <v-card class="round-16" outlined elevation="3">
            <v-card-text class="text-center">
              <p class="font-weight-bold black--text">Oops! You don't have a campaign</p>
              <p>Your campaign will be displayed here in real time. Please create a campaign to get a report on your campaign results.</p>
              <v-btn @click="goStarted()" class="text-capitalize" rounded color="primary">Get Started</v-btn>
            </v-card-text>
          </v-card>
          <!-- <v-card>
            <v-card-title class="text-center"><p class="text-center">
              Oops! you don't have a campaign
            </p></v-card-title>
            <v-card-text><p>Campaign Anda akan ditampilkan disini secara real time. Silahkan buat campaign untuk mendapatkan laporan detail progress hingga hasil campaign Anda.</p></v-card-text>
            <v-card-actions class="d-flex justify-center mt-0">
              <v-btn @click="goStarted()" class="text-capitalize" color="primary">Get Started</v-btn>
            </v-card-actions>
          </v-card> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  setup() {
    return{
      totalPrice:0,
      itemData:[],
      isLoading:false,
      total:0,
      serviceFee:0,
    }
  },
  mounted(){
    this.loadCampaigns()
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadCampaigns(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'campaign', {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          // console.log(response)
          if(response.status === 200){
            this.itemData = response.data.data
            for(let a = 0; a < this.itemData.length; a++){
              // this.itemData[a].date_added = new Date(this.itemData[a].date_added)
              this.itemData[a].date = this.checkCamp(new Date(this.itemData[a].start_date), new Date(this.itemData[a].end_date))
            }
            setTimeout(() => {
              this.isLoading = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    goToDetail(id){
      this.$router.push({name:'campaigns-detail', query: {id: this.encryptData(id)}});
    },
    downloadFile(campaign, id) {
      let token = localStorage.getItem('token')
      axios({
        url: process.env.VUE_APP_API_ENDPOINT + 'campaign/exportCampaign_/'+id,
        headers: {
          'Authorization': 'Bearer '+ token
        },
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'Report VDC '+campaign+'.xlsx';
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    goStarted(){
      // this.$router.push({name:'campaign'});
      window.open('https://wa.me/6285715160130?text=Halo Admin, Bisa diinformasikan paket influencer yang tersedia di VDC? Terima kasih', '_blank');
    },
    checkCamp(start, end){
      let startYear = start.toString().slice(11, 16)
      let startMonth = start.toString().slice(4, 7)
      let startDay = start.toString().slice(8, 10)
      let endYear = end.toString().slice(11, 16)
      let endMonth = end.toString().slice(4, 7)
      let endDay = end.toString().slice(8, 10)
      let date;
      if(startYear === endYear && startMonth === endMonth && startDay === endDay){
        date = startDay+' '+startMonth+' '+startYear
      } else  if(startYear === endYear && startMonth === endMonth && startDay != endDay){
        date = startDay+' - '+endDay+' '+startMonth+' '+startYear
      } else  if(startYear === endYear && startMonth != endMonth){
        date = startDay+' '+startMonth+' - '+endDay+' '+endMonth+' '+startYear
      } else if(startYear != endYear){
        date = startDay+' '+startMonth+' '+startYear+' - '+endDay+' '+startMonth+' '+endYear
      }
      return date
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    },
    dateFormat(value){
      return value.toString().slice(0, 24)
    },
    dateFormat2(value){
      return value.toString().slice(0, 16)
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    }
  },
})
</script>
